html {
  margin: 0;
  height: 100%;
  font-size: 14px;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  background: #f5f9ff;
}

.MuiToolbar-root h6 {
  font-size: 14px;
}